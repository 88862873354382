@import "bootstrap_customize";

/******************************
* util
*******************************/
.u-rounded-15 {
	border-radius: 15px;
}
.u-rounded-20 {
	border-radius: 20px;
}
.u-rounded-25 {
	border-radius: 25px;
}
.u-rounded-30 {
	border-radius: 30px;
}
.u-rounded-35 {
	border-radius: 35px;
}

.u-btn-reset {
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	padding: 0;
	appearance: none;
}

/******************************
* sidenav
*******************************/
/**
* sidenav
**/
.sidenav-bg {
	background-color: $sidenav-background;
}

.nav .nav-item .nav-link {
	color: $sidenav-text;
	font-weight: bold;
	padding: 12px 24px;

	&:hover {
		background: $palette-brown-light3;
	}

	&.active {
		background: $palette-brown-light2;
	}
}

/******************************
* login画面
*******************************/
.login-submit-bg-alpha {
	background-color: rgba(0, 0, 0, 0.35);

	&:hover {
		background-color: rgba(0, 0, 0, 0.55);
	}
}

/******************************
* dashboard画面
*******************************/

/******************************
* movie-info
*******************************/
.movie-info-bg-brown {
	background-color: $palette-brown-light3;
}

/******************************
* summary
*******************************/
.summary-text-gray {
	color: $palette-gray;
}

/******************************
* graph
*******************************/
.graph-bg-brown {
	background-color: $palette-brown-light3;
}

/******************************
* Pagination
*******************************/
.page-item.active .page-link {
	z-index: 3;
	color: #fff;
	background-color: #6d5e57;
	border-color: #6d5e57;
}

.page-link,
.page-item :hover {
	color: #6d5e57;
}

.page-link:focus {
	box-shadow: none;
}
