.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    padding: 2em;
    min-width: 80em;
    max-width: 70%;
    color: #6d5f57;
    background-color: #fff;
    border-radius: 1em;
    transform: translate(-50%, -50%);
    outline: transparent;
  }

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.2);
    z-index: 1000;
  }